import createHistory from "history/createBrowserHistory";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import  routerReducer  from "../index";
import thunk from "redux-thunk";

export const history = createHistory();

const initialState = {};


const middleware = [
  thunk,
  routerMiddleware(history)
];


const composedEnhancers = compose(
    applyMiddleware(...middleware),

  );

  export default createStore(
    routerReducer,
    initialState,
    composedEnhancers
  );