import { lazy } from "react";
const routeConfig = [
	{
		url: "home",
		path: "/home",
		title: "首页",
		key: "home",
		display: false,
	},
	{
		url: "memberManage",
		path: "/memberManage",
		title: "会员管理",
		key: "memberManage",
		display: true,
	},
	{
		url: "missionTypeManage",
		path: "/missionTypeManage",
		title: "任务类型管理",
		key: "missionTypeManage",
		display: true,
	},
	{
		url: "missionAudit",
		path: "/missionAudit",
		title: "任务审核",
		key: "missionAudit",
		display: true,
	},
	{
		url: "login",
		path: "/login",
		title: "任务审核",
		key: "login",
		display: false,
	},
	// {
	//     url: "missionList", path: "/missionList", title: "任务查询", key: "missionList", display: true
	// },
];

routeConfig.forEach((item) => {
	item.component = lazy(() => import(`../pages/pages/${item.url}`));
});

export default routeConfig;
