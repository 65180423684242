import React from 'react';
import './App.css';
// import createHashHistory from 'history/createHashHistory';
// import createHistory from "history/createBrowserHistory";
import { BrowserRouter as  Router} from "react-router-dom";
import Routes from "./route";

import { Provider } from "react-redux";
import store from "./redux/store";
// const his = createHistory();
function App() {
  return (
    <div className="App">
      <Provider store={store} >

      <Router>
        <Routes></Routes>
      </Router>
      </Provider>
    </div>
  );
}

export default App;
