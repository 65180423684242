import React, { Component } from "react";
import { Layout } from "antd";
import styles from "./index.module.scss";
import routes from "../../route/route";
import cln from "classnames";
import { withRouter } from "react-router-dom";
// import { BrowserRouter as  Router  } from "react-router-dom";
// import {browserHistory} from "react-router-dom"
// const his = createHistory();

const { Sider, Content } = Layout;
// @withRouter

class Index extends Component {
	componentWillMount() {
		let active = 1;
		routes.forEach((v, i) => {
			if (v.path === window.location.pathname) {
				active = i;
				this.setState({
					active,
				});
			}
		});

		// let result = sessionStorage.getItem('token');
		// if(!result){
		//     this.props.history.replace("/login")
		// }
	}
	state = {
		active: 0,
	};

	onclick = (item) => {
		console.log(this.props.history);
	};
	render() {
		const { active } = this.state;
		return (
			<Layout className={styles.Layout}>
				{!["/login"].includes(window.location.pathname) && (
					<Sider className={styles.Sider} width={256}>
						<p className={styles.systemName}>数据收集管理系统</p>
						{routes.map((res, index) => {
							return (
								res.display && (
									<dd
										onClick={() => {
											console.log(index);
											this.setState(
												{
													active: index,
												},
												() => {
													this.props.history.push(res.path);
												}
											);
										}}
										className={cln(
											styles.MenuItem,
											window.location.pathname === res.path && styles.active
										)}
										key={`${index}`}
									>
										<span>{res.title}</span>
									</dd>
								)
							);
						})}
					</Sider>
				)}

				<Content>{this.props.children}</Content>
			</Layout>
		);
	}
}

export default withRouter(Index);
