import React, { Suspense } from "react";
import routeConfig from "./route";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import Loading from "../Component/Loading";
import Layout from "../Layout/BasicLayout/index";
const SetInfo = function(item, props) {
	document.title = item.title || "";
	window.scrollTo(0, 0);
	return <item.component {...props} />;
};
export default () => {
	return (
		<Layout>
			<Suspense fallback={<Loading></Loading>}>
				<Switch>
					{routeConfig.map((res, index) => {
						return (
							<Route
								path={res.path}
								key={`router_${index}`}
								exact
								component={(props) => SetInfo(res, props)}
							></Route>
						);
					})}
					<Redirect exact path="/" to="/memberManage" />
				</Switch>
			</Suspense>
		</Layout>
	);
};
